import React from 'react';
import logo from '../assets/Group 20.png'; // Adjust the path as needed

export default function Navbar() {
    // Define the styles
    const navbarStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px', // Padding on left and right
        fontSize: '10px', // Adjust font size
        // borderBottom: '3px solid orange', // Orange bottom border
        margin:'10px 40px 5px 40px'
    };

    const logoStyle = {
        height: '40px', // Adjust size as needed
    };

    const itemStyle = {
        margin: '0 15px', // Spacing between items
        padding: '5px 10px', // Padding on left and right
    };

    const contactStyle = {
        padding: '8px 20px', // Padding on left and right
        border: '1px solid orange',
        borderRadius: '15px',
        borderBottom: '3px solid orange', // Orange bottom border
        backgroundColor: 'orange',
        // fontWeight: 'bold',
        fontSize: '14px', 
    };

    return (
        <div style={{borderBottom: '3px solid orange'}}>
            <div style={navbarStyle}>
            <div style={{ marginLeft: '1%' }}>
                <img src={logo} alt="Logo" style={logoStyle} />
            </div>
            <div style={{ display: 'flex' }}>
                <p style={itemStyle}>Products</p>
                <p style={itemStyle}>About Us</p>
                <p style={itemStyle}>Blog</p>
                <p style={itemStyle}>Support</p>
            </div>
            <div style={contactStyle}>
                Get in Touch
            </div>
        </div>
        </div>
    );
}
